import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import SideNavMobile from "./include/sideNavMobile";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import refreshToken from "../../misc/RefreshToken";
import AuthService from "../../misc/AuthService";
import CONSTANTS from "../../misc/Constants";
import axios from "axios";
import check from "../../iamges/check.png";
import Switch from "react-switch";
import { useMutation } from "react-query";

function Settings() {
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [assets, setAssets] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [currencyName, setCurrencyName] = useState("");
  const [icon, setIcon] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [fetchedBuyRate, setFetchedBuyRate] = useState("0");
  const [sellRate, setSellRate] = useState("");
  const [fetchedSellRate, setFetchedSellRate] = useState("0");
  const [normalFee, setNormalFee] = useState("");
  const [fetchedNormalFee, setFetchedNormalFee] = useState("0");
  const [priorityFee, setPriorityFee] = useState("");
  const [fetchedPriorityFee, setFetchedPriorityFee] = useState("0");
  const [minimumAmount, setMinimumAmount] = useState("");
  const [fetchedMinimumAmount, setFetchedMinimumAmount] = useState("0");
  const [instock, setInstock] = useState(false);
  const [fetchedInstock, setFetchedInstock] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [sidebarMobile, setSidebarMobile] = useState(false);
  const [isBuyUserInput, setIsBuyUserInput] = useState(false);
  const [isSellUserInput, setIsSellUserInput] = useState(false);
  const [isNormalUserInput, setIsNormalUserInput] = useState(false);
  const [isPriorityUserInput, setIsPriorityUserInput] = useState(false);
  const [isMinimumAmountUserInput, setIsMinimumAmountUserInput] =
    useState(false);
  const [isInstockUserInput, setIsInstockUserInput] = useState(false);
  // State to track if user has started input

  const handleBuyRateChange = (event) => {
    setBuyRate(event.target.value);
    setIsBuyUserInput(true); // Set user input flag to true
  };

  const handleSellRateChange = (event) => {
    setSellRate(event.target.value);
    setIsSellUserInput(true); // Set user input flag to true
  };

  const handleNormalFeeChange = (event) => {
    setNormalFee(event.target.value);
    setIsNormalUserInput(true); // Set user input flag to true
  };

  const handlePriorityFeeChange = (event) => {
    setPriorityFee(event.target.value);
    setIsPriorityUserInput(true); // Set user input flag to true
  };
  const handleMinimumAmountChange = (event) => {
    setMinimumAmount(event.target.value);
    setIsMinimumAmountUserInput(true); // Set user input flag to true
  };

  const handleInstock = () => {
    setInstock(!instock);
    setIsInstockUserInput(true);
  };
  const handleClick = (
    assetId,

    fetchedNormalFee,

    fetchedPriorityFee,
    fetchedBuyRate,
    fetchedSellRate,
    currencyName,
    network,

    fetchedMinimumAmount,
    fetchedInstock,
    code,
    icon
  ) => {
    setBuyRate(fetchedBuyRate);
    setSellRate(fetchedSellRate);
    setNormalFee(fetchedNormalFee);
    setPriorityFee(fetchedPriorityFee);
    setMinimumAmount(fetchedMinimumAmount);
    setInstock(fetchedInstock);
    setSelectedAssetId(assetId);

    // setNormalFee(normalFee);
    // setFastFee(fastFee);
    setFetchedBuyRate(fetchedBuyRate);
    setFetchedSellRate(fetchedSellRate);
    setFetchedNormalFee(fetchedNormalFee);
    setFetchedPriorityFee(fetchedPriorityFee);
    setFetchedMinimumAmount(fetchedMinimumAmount);
    setFetchedInstock(fetchedInstock);
    setCurrencyName(currencyName);
    // setCurrency(currency);
    // setMinimumAmount(minimumAmount);
    // setInstock(instock);
    // setCode(code);
    setShowForm(true);
    setIcon(icon);
  };

  const loadAssets = async () => {
    try {
      const response = await axios.get(
        CONSTANTS.API_BASE_URL + "/assets",
        AuthService.getAxiosAdminHeaders()
      );
      setAssets(response.data);
    } catch (error) {
      // console.error("Error loading assets:", error);
      if (error.response.status === 401) {
        refreshToken();
        // console.log("error", error.response);
      } else {
        return (window.location = "/server-offline");
        // console.log("error", error);
      }
    }
  };

  useEffect(() => {
    loadAssets();

    // eslint-disable-next-line
  }, [, selectedAssetId]);

  // console.log("assets", selectedAssetId);

  const saveSettings = (e) => {
    e.preventDefault();

    axios
      .patch(
        `${CONSTANTS.API_BASE_URL}/assets/${selectedAssetId}`,
        {
          buyRate: Number(buyRate),
          normalProcessingFee: Number(normalFee),
          priorityProcessingFee: Number(priorityFee),
          sellRate: Number(sellRate),
          minimumAmount: Number(minimumAmount),
          instock: instock,
        },
        AuthService.getAxiosAdminHeaders()
      )
      .then((response) => {
        // console.log("response", response);
        setSuccessMessage(`${currencyName} successfully updated`);
        window.modalShow("success-modal");
      })
      .catch((error) => {
        // console.log("error", error.response);
        if (error.response.status === 401) {
          refreshToken();
          // console.log("error", error.response);
        } else {
          return (window.location = "/server-offline");
          // console.log("error", error);
        }
      });
  };

  const postMessage = async ({ title, message }) => {
    const response = await axios.post(
      `${CONSTANTS.API_BASE_URL}/notifications/users`,
      {
        title,
        message,
      },
      AuthService.getAxiosAdminHeaders()
    );

    return response.data;
  };


  const postChallenges = async ({ image, title, description, body }) => {
    const response = await axios.post(
      `${CONSTANTS.API_BASE_URL}/admin/users/challenges`,
      {
        image,
        title,
        description,
        body
      },
      AuthService.getAxiosAdminHeaders()
    );

    return response.data;
  }

 
  const mutation = useMutation(postMessage, {
    onSuccess: () => {
      setSuccessMessage("Notification successfully sent");
      window.modalShow("success-modal");
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

 

  const handleSubmit = (event) => {
    event.preventDefault();
    const title = event.target.title.value;
    const message = event.target.message.value;

    mutation.mutate({ title, message });
  };

  const postmutation = useMutation(postChallenges, {
    onSuccess: () => {
      setSuccessMessage("Challenges successfully posted");
      window.modalShow("success-modal");
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  })
  const handleSubmitChallenges = (event) => {
    event.preventDefault(); 
    const image = event.target.image.value;
    const title = event.target.title.value;
    const description = event.target.description.value;
    const body = event.target.body.value;
    
    postmutation.mutate({ image, title, description, body });
  };

  return (
    <Fragment>
      <MetaTags>
        <title>System Settings - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body border">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {sidebarMobile && (
              <SideNavMobile close={() => setSidebarMobile(!sidebarMobile)} />
            )}
            <SideNav className="bigScreen" active="settings" />
            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper  relative"
            >
              <div className="header-mobile py-3  ">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>
                  <button
                    className="btn btn-icon btn-active-color-primary"
                    id="kt_aside_toggle"
                    onClick={() => setSidebarMobile(!sidebarMobile)}
                  >
                    <span className="svg-icon svg-icon-2x me-n1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <Header title="System Settings" />

              <div className="flex flex-col lg:flex-row md:flex-row items-start lg:items-start md:items-start w-full inset-x-0 top-0 ">
               

                <div className=" flex flex-col md:mt-0 mt-4">
                    <div className="flex flex-col items-start w-[350px] md:w-[400px] border-l border-r border-t rounded-[10px] pt-2 mr-0 lg:ml-20 overflow-hidden  overflow-x-visible  md:mt-2 ">
                      <div className="border-b py-2 px-4 overflow-hidden w-full">
                        <li className="text-left"> Select asset</li>
                      </div>
                      <div className="w-full">
                        <ul className="list-none">
                          {assets &&
                            assets.map((asset) => (
                              <div
                                key={asset.id} // Use a unique identifier for each item
                                className={`py-3 px-4  border-b last:overflow-hidden border-${
                                  selectedAssetId === asset.walletId
                                    ? " border border-theme last:rounded-b-[10px] "
                                    : ""
                                }
                               

                            }`}
                                onClick={() =>
                                  handleClick(
                                    asset.walletId,
                                    asset.data.normalProcessingFee,
                                    asset.data.priorityProcessingFee,
                                    asset.data.buyRate,
                                    asset.data.sellRate,
                                    asset.name,
                                    asset.network,
                                    asset.data.minimumAmount,
                                    asset.data.instock,
                                    asset.code,
                                    asset.icon
                                  )
                                }
                                style={{
                                  background: `${
                                    selectedAssetId === asset.walletId
                                      ? "rgb(0,80,255, .05)"
                                      : "white"
                                  }`,
                                }}
                              >
                                <div className="flex w-full cursor-pointer">
                                  <div>
                                    <img
                                      src={asset.icon}
                                      alt=""
                                      className="w-8 h-8"
                                    />
                                  </div>
                                  <div className="ml-3 items-start flex flex-col">
                                    <div>
                                      {" "}
                                      <li className="text-left text-bold text-[16px]">
                                        {asset.name}
                                      </li>
                                    </div>

                                    <div className="flex">
                                      <li className="text-left">
                                        {asset.code}
                                      </li>
                                      <li className="text-left bg-green-500 rounded-[5px] ml-2 px-1 text-white text-[14px]">
                                        {asset.networkName}
                                      </li>
                                    </div>
                                  </div>
                                  {selectedAssetId === asset.walletId && (
                                    <img
                                      src={check}
                                      alt="Selected"
                                      className="ml-auto h-6 w-6"
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                        </ul>
                      </div>
                    </div>

                    {/* form */}
                  </div>
                {showForm && (
                  
                  <div className="flex flex-col items-start  md:w-[400px] border rounded-[10px] mr-0 lg:mr-9 mt-6 md:mt-2 lg:mt-0 lg:mx-16 pt-2  ">
                    
                    <div id="" className="admin-settingsContent flex a-i-s">
                      <div>
                        <div className="w-100 flex border-bottom p-3 ">
                          <div className="">
                            <img src={icon} alt="" className="w-10 h-10" />
                          </div>
                          <div className="py-2 px-4 overflow-hidden w-full">
                            {currencyName} Order Settings
                          </div>
                        </div>
                        <div className="card-body" style={{}}>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">
                              {currencyName} Buy Rate
                            </label>
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-12 fv-row">
                                  <div className="input-group mb-5">
                                    <span className="input-group-text">
                                      GHS
                                    </span>
                                    <input
                                      type="text"
                                      name="bitcoin_buy_rate"
                                      value={
                                        isBuyUserInput
                                          ? buyRate
                                          : fetchedBuyRate
                                      } // Prioritize user input
                                      className="form-control form-control-lg mb-3 mb-lg-0"
                                      onChange={handleBuyRateChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">
                              {currencyName} Sell Rate
                            </label>
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-12 fv-row">
                                  <div className="input-group mb-5">
                                    <span className="input-group-text">
                                      GHS
                                    </span>
                                    <input
                                      type="text"
                                      name="bitcoin_sell_rate"
                                      value={
                                        isSellUserInput
                                          ? sellRate
                                          : fetchedSellRate
                                      } // Prioritize user input
                                      className="form-control form-control-lg mb-3 mb-lg-0"
                                      onChange={handleSellRateChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">
                              {currencyName} Normal Fee
                            </label>
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-12 fv-row">
                                  <div className="input-group mb-5">
                                    <span className="input-group-text">
                                      USD
                                    </span>
                                    <input
                                      type="text"
                                      name="bitcoin_normal_fee"
                                      value={
                                        isNormalUserInput
                                          ? normalFee
                                          : fetchedNormalFee
                                      } // Prioritize user input
                                      className="form-control form-control-lg mb-3 mb-lg-0"
                                      onChange={handleNormalFeeChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">
                              {currencyName} Priority Fee
                            </label>
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-12 fv-row">
                                  <div className="input-group mb-5">
                                    <span className="input-group-text">
                                      USD
                                    </span>
                                    <input
                                      type="text"
                                      name="bitcoin_priority_fee"
                                      value={
                                        isPriorityUserInput
                                          ? priorityFee
                                          : fetchedPriorityFee
                                      } // Prioritize user input
                                      className="form-control form-control-lg mb-3 mb-lg-0"
                                      onChange={handlePriorityFeeChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label required fw-bold fs-6">
                              Minimum Amount
                            </label>
                            <div className="col-lg-8">
                              <div className="row">
                                <div className="col-lg-12 fv-row">
                                  <div className="input-group mb-5">
                                    <span className="input-group-text">
                                      USD
                                    </span>
                                    <input
                                      type="text"
                                      name="bitcoin_priority_fee"
                                      value={
                                        isMinimumAmountUserInput
                                          ? minimumAmount
                                          : fetchedMinimumAmount
                                      } // Prioritize user input
                                      className="form-control form-control-lg mb-3 mb-lg-0"
                                      onChange={handleMinimumAmountChange}
                                    />
                                  </div>
                                </div>
                                {/* {
                                this.state.settingsFormError.bitcoin_minimumAmoun.length > 0 && 
                                <p className="text-danger fs-12px">{this.state.settingsFormError.bitcoin_minimumAmount}</p>
                              } */}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="col-lg-4 col-form-label required fw-bold fs-6  ">
                              Stock
                            </div>
                            <div className="d-flex align-items-center pr-10">
                              <div className="ml-2">
                                <Switch
                                  checked={
                                    isInstockUserInput
                                      ? instock
                                      : fetchedInstock
                                  }
                                  onChange={handleInstock}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                />
                              </div>
                            </div>
                            <div className="p-4">
                              <span className="fs-6">In stock</span>
                            </div>
                          </div>

                          <div className="d-flex justify-content-start pt-10">
                            <button
                              onClick={saveSettings}
                              className="btn btn-primary"
                            >
                              <span className=""> Save Changes</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                 <div className="flex flex-col items-start w-[350px] md:w-[500px]   rounded-[10px]  mt-6 md:mt-2 lg:mt-0 lg:mx-4 pt-2 ml-0 md:ml-4">
                  {/* notifications form */}
                  <div> Send Notifications</div>
                  <form onSubmit={handleSubmit} className="w-full">
                    <div className="form-group row mb-3 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Title:
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          name="title"
                          className="form-control form-control-lg mb-3 mb-lg-0"
                          required
                        />
                      </div>
                    </div>
                    <div className=" row mt-6 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Message:
                      </label>
                      <div className="col-lg-8">
                        <textarea name="message" required rows="4" cols="46" />
                      </div>
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-8 offset-lg-4">
                        <button
                          className={`btn btn-primary ${
                            mutation.isLoading ? "btn-disabled" : ""
                          }`}
                          type="submit"
                          disabled={mutation.isLoading}
                        >
                          {mutation.isLoading ? (
                            <>
                              <span className="loading-spinner"></span>
                              Sending...
                            </>
                          ) : (
                            "Send Notifications"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  

                  {/* challenges form */}
                  <div> Set Challenges</div>

                  <form onSubmit={handleSubmitChallenges} className="w-full mt-4">
                    <div className="form-group row mb-3 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Title:
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          name="title"
                          className="form-control form-control-lg mb-3 mb-lg-0"
                          required
                        />
                      </div>

                     

                    </div>
                    <div className="form-group row mb-3 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Description:
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          name="description"
                          className="form-control form-control-lg mb-3 mb-lg-0"
                          required
                        />
                      </div>

                    </div>

                    <div className="form-group row mb-3 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Image:
                      </label>
                      <div className="col-lg-8">
                        <input
                          type="text"
                          name="image"
                          className="form-control form-control-lg mb-3 mb-lg-0"
                          required
                        />
                      </div>

                    </div>

                    
                    <div className=" row mt-6 items-start">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        Body:
                      </label>
                      <div className="col-lg-8">
                        <textarea name="body" required rows="4" cols="46" />
                      </div>
                    </div>
                    <div className="form-group row mt-4">
                      <div className="col-lg-8 offset-lg-4">
                        <button
                          className={`btn btn-primary ${
                            mutation.isLoading ? "btn-disabled" : ""
                          }`}
                          type="submit"
                          disabled={mutation.isLoading}
                        >
                          {mutation.isLoading ? (
                            <>
                              <span className="loading-spinner"></span>
                              Sending...
                            </>
                          ) : (
                            "Set Challenge"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className="modal fade"
        id="success-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <div className="py-6 flex flex-row items-center justify-center   w-100 px-10">
                <div className="svg-icon svg-icon-5x svg-icon-primary mb-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.3"
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="10"
                      fill="black"
                    />
                    <path
                      d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div></div>
              </div>
              <h4 className="nk-modal-title">Updated Successfully</h4>
              <div className="nk-modal-text">
                <p className="lead">{successMessage}</p>
              </div>
              <div className="nk-modal-action py-10">
                <button
                  className="btn btn-lg btn-mw btn-light m-1"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Settings;
